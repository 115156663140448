<template>
    <div>
        <CtHeader></CtHeader>
        <CtAboutUs></CtAboutUs>
        <CtEnvironment></CtEnvironment>
        <CtDishes></CtDishes>
        <CtFooter></CtFooter>
    </div>
</template>

<script>
import CtHeader from '../components/Header'
import CtAboutUs from '../components/AboutUs'
import CtEnvironment from '../components/Environment'
import CtDishes from '../components/Dishes'
import CtFooter from '../components/Footer'

export default {
    components: {
        CtHeader,
        CtAboutUs,
        CtEnvironment,
        CtDishes,
        CtFooter
    }
}
</script>

<style lang="scss">
</style>