<template>
    <div class="about-home">
        <img class="main-pic" src="../assets/main_pic_us.png" alt="">
        <div class="ab-container">
            <div>
                <div class="ab-text">{{$t('message.abus')}}</div>
                <div class="ab-desc">{{$t('message.abusDesc')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scss="lang">
    .about-home {
        display: flex;
        /* height: 300px; */
        width: 100%;
        padding: 45px;
        box-sizing: border-box;
    }
    .main-pic {
        width: 606px;
        margin-right: 45px;
    }
    .ab-text {
        color: #3f231b;
        font-family: Barlow-bold;
        font-size: 54px;
        margin-bottom: 20px;
        /* margin: 34px 0 20px; */
    }
    .ab-desc {
        font-family: Barlow;
        font-size: 21px;
        color: #3f231b;
        opacity: 0.64;
        word-break: break-all;
    }
    .ab-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
</style>