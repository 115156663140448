<template>
    <div class="dishes">
        <div class="dishes-bg">
            <div class="dishes-swiper-home">
                <div class="dishes-swiper-header">
                    <img class="p-c" @click="prevHandler" src="../assets/main_left.png" alt="">
                        <div class="dished-swiper-text">
                            <div class="text1">{{$t('message.DISHES')}}</div>
                            <div class="text2">{{$t('message.SPECIALDISHES')}}</div>
                        </div>
                    <img class="p-c" @click="nextHandler" src="../assets/main_right.png" alt="">
                </div>
                <div class="dishes-swiper-container">
                <swiper ref="mySwiper" :options="swiperOptions">
                    <swiper-slide>
                        <div class="swiper-slide-home">
                            <div class="slide-home-img">
                                <img @click="openBigImgHandler('/dishes_pic1@2x.png')" src="../assets/dishes_pic1.png" alt="">
                            </div>
                            <div class="slide-text1">Lobster In Dry Pot</div>
                            <!-- <div class="slide-text1">$54</div> -->
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="swiper-slide-home">
                            <div class="slide-home-img">
                                <img @click="openBigImgHandler('/dishes_pic2@2x.png')" src="../assets/dishes_pic2.png" alt="">
                            </div>
                            <div class="slide-text1">Lemon Fish Fillet</div>
                            <!-- <div class="slide-text1">$24</div> -->
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="swiper-slide-home">
                            <div class="slide-home-img">
                                <img @click="openBigImgHandler('/dishes_pic3@2x.png')" src="../assets/dishes_pic3.png" alt="">
                            </div>
                            <div class="slide-text1">Husband And Wife Special</div>
                            <!-- <div class="slide-text1">$16</div> -->
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="swiper-slide-home">
                            <div class="slide-home-img">
                                <img @click="openBigImgHandler('/dishes_pic4@2x.png')" src="../assets/dishes_pic4.png" alt="">
                            </div>
                            <div class="slide-text1">Beef soup</div>
                            <!-- <div class="slide-text1">$24</div> -->
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="swiper-slide-home">
                            <div class="slide-home-img">
                                <img @click="openBigImgHandler('/dishes_pic5@2x.png')" src="../assets/dishes_pic5.png" alt="">
                            </div>
                            <div class="slide-text1">Beef Stew</div>
                            <!-- <div class="slide-text1">$26</div> -->
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="swiper-slide-home">
                            <div class="slide-home-img">
                                <img @click="openBigImgHandler('/dishes_pic6@2x.png')" src="../assets/dishes_pic6.png" alt="">
                            </div>
                            <div class="slide-text1">Seafood Fest</div>
                            <!-- <div class="slide-text1">$22</div> -->
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="swiper-slide-home">
                            <div class="slide-home-img">
                                <img @click="openBigImgHandler('/dishes_pic7@2x.png')" src="../assets/dishes_pic7.png" alt="">
                            </div>
                            <div class="slide-text1">Hutaoli Fried Fice</div>
                            <!-- <div class="slide-text1">$16</div> -->
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="swiper-slide-home">
                            <div class="slide-home-img">
                                <img @click="openBigImgHandler('/dishes_pic8@2x.png')" src="../assets/dishes_pic8.png" alt="">
                            </div>
                            <div class="slide-text1">Beef Pancake Roll</div>
                            <!-- <div class="slide-text1">$12</div> -->
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
                </div>
            </div>
            <el-dialog
                top='0'
                :visible.sync="dialogVisible"
                width="50%"
            >
                <img style="width: 100%;" :src="bigImg" alt="">
                
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
    data() {
      return {
        dialogVisible: false,
        bigImg: '',
        swiperOptions: {
        //   loop: true,
          pagination: {
            el: '.swiper-pagination'
          },
          on: {
                click: function (a) {
                  // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法 
                  // console.log(this); // -> Swiper
                  // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
                  console.log(a)
                }
              },
          slidesPerView: 4,
          spaceBetween : 20,
          // Some Swiper option/callback...
        }
      }
    },
    methods: {
        openBigImgHandler (url) {
          this.dialogVisible = true
          console.log(url)
          this.bigImg = require('@/assets' + url)
      },
      nextHandler () {
        //   console.log(this.$refs.mySwiper)
          this.$refs.mySwiper.$swiper.slideNext()
      }, 
      prevHandler () {
          this.$refs.mySwiper.$swiper.slidePrev()
      }
    },
    components: {
        Swiper,
        SwiperSlide
    },
}
</script>

<style lang="scss">
    .dishes {
        &-bg {
            position: relative;
            height: 950px;
            background-image: url('../assets/bg.png');
            background-position: top center;
            background-repeat: no-repeat;
            background-size: 100% 50%;
            background-color: #f5f5f5;
        }
        &-swiper-home {
            position: absolute;
            width: 90%;
            height: 600px;
            background-color: #fff;
            box-shadow: 0px 3px 6px 0px 
		rgba(0, 0, 0, 0.16);
            bottom: 10%;
            left: 50%;
            transform: translateX(-50%);
            padding: 40px;
        }
        &-swiper-header {
            width: 100%;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .dished-swiper-text {
            .text1 {
                color: #3f231b;
                font-family: Barlow-bold;
                font-size: 54px;
                text-align: center;
                margin-bottom: 20px;
            }
            .text2 {
                width: 308px;
                height: 49px;
                line-height: 49px;
                color: #fff;
                text-align: center;
                background-color: #3f231b;
                border-radius: 4px;
                opacity: 0.6;
            }
        }
        .p-c {
            cursor: pointer;
        }
        .dishes-swiper-container {
            height: 400px;
            width: 100%;
        }
        .swiper-slide-home {
            height: 380px;
            .slide-home-img {
                width: 100%;
                margin-bottom: 10px;
                overflow: hidden;
            }
            img {
                display: block;
                // height: 300px;
                width: 100%;
                transition:all 0.5s ease-out;
            }
            img:hover {
                transform:scale(1.2);
            }
        }
        .slide-text1 {
            font-family: Barlow-bold;
            font-size: 16px;
            color: #151515;
            text-align: center;
        }
    }
</style>