<template>
  <div class="index">
    <div class="bg">
      <img class="logo" src="../assets/imglogo.png" alt="" />
      <el-select
        class="ct-select"
        @change="lanChange"
        v-model="value"
        placeholder="English"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="banner-text">{{ $t("message.occasion") }}</div>
      <div class="ct-form">
        <el-date-picker
          class="date"
          v-model="value1"
          type="date"
          :placeholder="$t('message.date')"
        >
        </el-date-picker>
        <el-select
          class="time"
          v-model="timeValue"
          clearable
          :placeholder="$t('message.ps')"
        >
          <i slot="prefix" class="el-input__icon el-icon-time"></i>
          <el-option
            v-for="item in timesArr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          class="person"
          v-model="personValue"
          clearable
          :placeholder="$t('message.ps')"
        >
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
          <el-option
            v-for="item in $i18n.locale == 'en' ? personArr : personChArr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <router-link
          tag="a"
          to="/reserve"
          class="confirm-btn"
          @click="confirmBtn"
          >{{ $t("message.confirmText") }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // pickerOptions: {
      //     disabledDate(time) {
      //         return time.getTime() < Date.now();
      //     }
      // },
      timesArr: [
        { value: "11:00 AM", label: "11:00 AM" },
        { value: "11:30 AM", label: "11:30 AM" },
        { value: "12:00 AM", label: "12:00 AM" },
        { value: "12:30 AM", label: "12:30 AM" },
        { value: "1:00 PM", label: "1:00 PM" },
        { value: "1:30 PM", label: "1:30 PM" },
        { value: "2:00 PM", label: "2:00 PM" },
        { value: "2:30 PM", label: "2:30 PM" },
        { value: "3:00 PM", label: "3:00 PM" },
        { value: "3:30 PM", label: "3:30 PM" },
        { value: "4:00 PM", label: "4:00 PM" },
        { value: "4:30 PM", label: "4:30 PM" },
        { value: "5:00 PM", label: "5:00 PM" },
        { value: "5:30 PM", label: "5:30 PM" },
        { value: "6:00 PM", label: "6:00 PM" },
        { value: "6:30 PM", label: "6:30 PM" },
        { value: "7:00 PM", label: "7:00 PM" },
        { value: "7:30 PM", label: "7:30 PM" },
        { value: "8:00 PM", label: "8:00 PM" },
        { value: "8:30 PM", label: "8:30 PM" },
        { value: "9:00 PM", label: "9:00 PM" },
        { value: "9:30 PM", label: "9:30 PM" },
      ],
      value1: new Date(),
      personArr: [
        { value: "1", label: "1 person" },
        { value: "2", label: "2 people" },
        { value: "3", label: "3 people" },
        { value: "4", label: "4 people" },
        { value: "5", label: "5 people" },
        { value: "6", label: "6 people" },
        { value: "7", label: "7 people" },
        { value: "8", label: "8 people" },
        { value: "9", label: "9 people" },
        { value: "10", label: "10 people" },
        { value: "11", label: "11 people" },
        { value: "12", label: "12 people" },
        { value: "13", label: "13 people" },
        { value: "14", label: "14 people" },
        { value: "15", label: "15 people" },
        { value: "16", label: "16 people" },
        { value: "17", label: "17 people" },
        { value: "18", label: "18 people" },
        { value: "19", label: "19 people" },
        { value: "20", label: "20 people" },
        { value: "21", label: "Larger party" },
      ],
      personChArr: [
        { value: "1", label: "1 人" },
        { value: "2", label: "2 人" },
        { value: "3", label: "3 人" },
        { value: "4", label: "4 人" },
        { value: "5", label: "5 人" },
        { value: "6", label: "6 人" },
        { value: "7", label: "7 人" },
        { value: "8", label: "8 人" },
        { value: "9", label: "9 人" },
        { value: "10", label: "10 人" },
        { value: "11", label: "11 人" },
        { value: "12", label: "12 人" },
        { value: "13", label: "13 人" },
        { value: "14", label: "14 人" },
        { value: "15", label: "15 人" },
        { value: "16", label: "16 人" },
        { value: "17", label: "17 人" },
        { value: "18", label: "18 人" },
        { value: "19", label: "19 人" },
        { value: "20", label: "20 人" },
        { value: "21", label: "大家庭" },
      ],

      options: [
        {
          value: "en",
          label: "English",
        },
        {
          value: "cn",
          label: "Chinese",
        },
      ],
      value: "en",
      timeValue: "11:00 PM",
      personValue: "2",
    };
  },
  methods: {
    lanChange(value) {
      console.log(value);
      this.$i18n.locale = value;
    },
    confirmBtn() {
      console.log(this.dateFormat(this.value1));
    },
  },
};
</script>

<style lang="scss">
.bg {
  position: relative;
  width: 100%;
  height: 542px;
  background-image: url("../assets/banner.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  .date {
    .el-input__inner {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .time {
    .el-input__inner {
      border-radius: 0px;
    }
  }
  .el-input__inner {
    height: 50px;
    line-height: 50px;
    border: 1px solid #eceef3;
    border-left: none;
  }
  .person {
    .el-input__inner {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  .el-input {
    font-size: 16px;
  }
}
.logo {
  position: absolute;
  width: 180px;
  top: 0px;
  left: 71px;
}
.index {
  .ct-select {
    position: absolute;
    top: 30px;
    right: 45px;
    color: #fff;
    font-family: Barlow;
    input::-webkit-input-placeholder {
      color: #fff;
    }
  }
  .ct-select .el-input__inner {
    background-color: transparent;
    border: none;
    color: #fff;
    width: 120px;
  }
  .ct-select .el-input .el-select__caret {
    color: #fff;
  }
}
.banner-text {
  position: absolute;
  font-family: Italic;
  font-size: 48px;
  color: #fff;
  top: 40%;
  left: 49%;
  transform: translate(-50%, -50%);
}
.ct-form {
  display: flex;
  align-items: center;
  position: absolute;
  // width: 636px;
  top: 60%;
  left: 50%;
  height: 40px;
  transform: translate(-50%, -50%);
}
.confirm-btn {
  width: 110px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #3f231b;
  color: #fff;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
  font-family: Italic;
}
</style>