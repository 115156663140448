<template>
    <div class="footer">
        <div>
            <img class="footer-logo" src="../assets/imglogo.png" alt="">
            <div>
                <div class="">
                    Weekday : 11:00am - 22:00pm
                </div>
                <div class="">
                    <!-- Weekend : 11:00am - 22:00pm -->
                </div>
                <div class="foot-diliver"></div>
                <div class="flex-common">Get Social
                    <img style="margin-left: 20px" src="../assets/footer_icon_facebook.png" alt="">
                    <img src="../assets/footer_icon_ins.png" alt="">
                </div>
            </div>
        </div>
        <div>
            <div class="foot-header-title">CONTACT</div>
            <div class="flex-common mb-20">
                <img src="../assets/footer_icon_address.png" alt="">
                <div class="contact-us-title">42 W 33rd St, New York
NY 10001 </div>
            </div>
            <div class="flex-common mb-20">
                <img src="../assets/footer_icon_call.png" alt="">
                <div class="contact-title">(917) 261-6989</div>
            </div>
            <div class="flex-common">
                <img src="../assets/footer_icon_email.png" alt="">
                <div class="contact-us-title">contact@hutaoli.net</div>
            </div>
        </div>
        <div>
            <div class="foot-header-title">CONTACT US</div>
            <div class="contact-us-title">Subscribe to our newsletter to receive weekly news,
updayes,special offers,and exclusive discounts.</div>
            <div class="footer-input-home">
                <input class="footer-input" type="text" placeholder="Email">
                <img class="send-img" src="../assets/footer_icon_send.png" alt="">
            </div>
        </div>
        <div class="banquan">
            Copyright @ USA QR Culture Industrial Development LLC all right reserved . Web developer Craft Times Inc.
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
    .footer {
        position: relative;
        display: flex;
        justify-content: space-around;
        height: 523px;
        background-color: #22120d;
        width: 100%;
        color: #fff;
        .banquan {
            position: absolute;
            width: 90%;
            top: 85%;
            border-top: 1px solid #443632;
            height: 50px;
            line-height: 50px;
            color: #bdb8b6;
        }
        .footer-logo {
            width: 177px;
            margin-top: 27px;
            margin-bottom: 40px;
        }
        .foot-diliver {
            width: 100%;
            margin: 50px 0;
            border-top: solid 1px #fff;
            opacity: 0.39;
            // bor
        }
        .flex-common {
            display: flex;
            align-items: center;
            img {
                margin-right: 20px;
            }
        }
        .foot-header-title {
            font-size: 28px;
            margin-top: 110px;
            margin-bottom: 69px;
            font-weight: bold;
        }
        .contact-title {
            width: 250px;
            // margin-left: 20px;
        }
        .contact-us-title {
            width: 350px;
            // margin-left: 20px;
        }
        .mb-20 {
            margin-bottom: 40px;
        }
        .footer-input {
            outline: none;
            border: none;
            background-color: transparent;
            border-bottom: solid 1px #e6e6e6;
            height: 30px;
            margin-top: 60px;
            width: 230px;
            color: #fff;
        }
        .footer-input-home {
            display: flex;
            align-items: flex-end;
        }
        .send-img {
            width: 54px;
            margin-left: 20px;
        }
        input::-webkit-input-placeholder {
                color: #fff;
            }
    }
</style>