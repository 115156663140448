<template>
  <div class="environment">
    <div class="en-header">
      <div class="header-flex">
        <div class="header-txt">{{$t('message.ENVIRONMENT')}}</div>
        <div class="header-arrow">
          <img @click="prevHandler" src="../assets/main_icon_left.png" alt="" />
          <img @click="nextHandler" src="../assets/main_icon_right.png" alt="" />
        </div>
      </div>
      <div class="swiper-home">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide>
            <img @click="openBigImgHandler('/ent_pic5@3x.png')" src="../assets/ent_pic5.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img @click="openBigImgHandler('/ent_pic6@3x.png')" src="../assets/ent_pic6.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img @click="openBigImgHandler('/ent_pic7@3x.png')" src="../assets/ent_pic7.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img @click="openBigImgHandler('/ent_pic8@3x.png')" src="../assets/ent_pic8.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img @click="openBigImgHandler('/ent_pic9@3x.png')" src="../assets/ent_pic9.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img @click="openBigImgHandler('/ent_pic10@3x.png')" src="../assets/ent_pic10.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img @click="openBigImgHandler('/ent_pic11@3x.png')" src="../assets/ent_pic11.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img @click="openBigImgHandler('/ent_pic12@3x.png')" src="../assets/ent_pic12.png" alt="" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <el-dialog
        :visible.sync="dialogVisible"
        width="50%"
      >
        <img style="width: 100%;" :src="bigImg" alt="">
        
      </el-dialog>
    </div>
    <div class="diliver"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  data() {
    return {
      bigImg: '',
      dialogVisible: false,
      swiperOptions: {
        // loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
        slidesPerView: 4,
        // Some Swiper option/callback...
      },
    };
  },
  methods: {
      openBigImgHandler (url) {
          this.dialogVisible = true
          console.log(url)
          this.bigImg = require('@/assets' + url)
      },
      nextHandler () {
        //   console.log(this.$refs.mySwiper)
          this.$refs.mySwiper.$swiper.slideNext()
      }, 
      prevHandler () {
          this.$refs.mySwiper.$swiper.slidePrev()
      }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss">
.environment {
  .en-header {
    margin: 0 45px;
  }
  .header-flex {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: solid 2px #eaeaea;
  }
  .header-txt {
    color: #3f231b;
    font-size: 54px;
    font-family: Barlow-bold;
  }
  .swiper-home {
    height: 350px;
    img {
      height: 100%;
    }
  }
  .swiper-home {
    padding: 30px 0;
  }
  .swiper-container {
    height: 100%;
  }
  .header-arrow {
    display: flex;
    align-items: center;
    img {
      height: 60%;
      cursor: pointer;
      margin-left: 20px;
    }
  }
  .diliver {
    width: 100%;
    height: 60px;
    background-color: #f5f5f5;
  }
  // .swiper-slide {
  //     img {
  //         width: 100%;
  //     }
  // }
  .swiper-slide {
      overflow: hidden;
      img {
          transition:all 0.5s ease-out;
      }
      img:hover {
                transform:scale(1.2);
            }
  }
}
</style>