<template>
  <div class="reserve">
    <div class="reserve-bg"></div>
    <div class="reserve-home">
      <div class="reserve-left">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="Overview" name="first">
            <div class="d-flex mt-60 mb-30 aic">
              <div class="tip-cards"></div>
              <div class="tip-text ml-20">Brand culture</div>
            </div>
            <div class="tip-desc">
              New York Hutaoli music restaurant & bar is wholly invested by USA
              QR culture industry development LLC , the new style of experience
              restaurant, Hutaoli integrates music, literature and art, Sichuan
              cuisine restaurant and red wine bar. It creates a new one-stop
              entertainment experience with a new fusion mode of bar, restaurant
              and cafe. It is a new landmark of nightlife with more cultural
              atmosphere than bar.
            </div>
            <div class="d-flex mt-60 mb-30 aic">
              <div class="tip-cards"></div>
              <div class="tip-text ml-20">Safety precautions</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="w-50">
                <div class="cleaning-sanitizing">Cleaning & Sanitizing</div>
                <div class="d-flex mt-20">
                  <img class="w-h-22" src="../assets/icon_yes.png" alt="" />
                  <div class="tip-desc ml-20">
                    Surfaces sanitized between seatings
                  </div>
                </div>
                <div class="d-flex mt-8">
                  <img class="w-h-22" src="../assets/icon_yes.png" alt="" />
                  <div class="tip-desc ml-20">
                    Common areas deep cleaned daily
                  </div>
                </div>
                <div class="d-flex mt-8">
                  <img class="w-h-22" src="../assets/icon_yes.png" alt="" />
                  <div class="tip-desc ml-20">
                    Digital, disposable or sanitized menu provided
                  </div>
                </div>
                <div class="d-flex mt-8">
                  <img class="w-h-22" src="../assets/icon_yes.png" alt="" />
                  <div class="tip-desc ml-20">
                    Sanitizer or wipes provided for customers
                  </div>
                </div>
                <div class="d-flex mt-8">
                  <img class="w-h-22" src="../assets/icon_yes.png" alt="" />
                  <div class="tip-desc ml-20">
                    Contactless payment available
                  </div>
                </div>
              </div>
              <div class="w-50">
                <div class="cleaning-sanitizing">Physical Distancing</div>
                <div class="d-flex mt-20">
                  <img class="w-h-22" src="../assets/icon_yes.png" alt="" />
                  <div class="tip-desc ml-20">
                    Limited number of seated diners
                  </div>
                </div>
                <div class="d-flex mt-8">
                  <img class="w-h-22" src="../assets/icon_yes.png" alt="" />
                  <div class="tip-desc ml-20">
                    Distancing maintained in common areas
                  </div>
                </div>
                <div class="d-flex mt-8">
                  <img class="w-h-22" src="../assets/icon_yes.png" alt="" />
                  <div class="tip-desc ml-20">Extra space between tables</div>
                </div>
              </div>
              <div class="w-50 mt-20">
                <div class="cleaning-sanitizing">Screening</div>
                <div class="d-flex mt-30">
                  <img class="w-h-22" src="../assets/icon_yes.png" alt="" />
                  <div class="tip-desc ml-20">
                    Sick staff prohibited in the workplace
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex mt-60 mb-30"
              style="justify-content: space-between"
            >
              <div class="d-flex aic">
                <div class="tip-cards"></div>
                <div class="tip-text ml-20">Photos</div>
              </div>
              <!-- <div class="d-flex more-btn">more ></div> -->
            </div>
            <div class="more-img d-flex" style="justify-content: space-between">
              <div style="width: 59%">
                <img
                  style="width: 100%; height: 100%"
                  src="../assets/pht1.png"
                  alt=""
                />
              </div>
              <div
                class="d-flex flex-wrap"
                style="
                  justify-content: space-between;
                  width: 40%;
                  align-items: ;
                "
              >
                <img
                  src="../assets/pht2.png"
                  style="height: 49%; width: 49%"
                  alt=""
                />
                <img
                  src="../assets/pht3.png"
                  style="height: 49%; width: 49%"
                  alt=""
                />
                <img
                  src="../assets/pht2.png"
                  style="height: 49%; width: 49%"
                  alt=""
                />
                <img
                  src="../assets/pht3.png"
                  style="height: 49%; width: 49%"
                  alt=""
                />
              </div>
            </div>
            <div class="d-flex mt-60 mb-30 aic">
              <div class="tip-cards"></div>
              <div class="tip-text ml-20">Other</div>
            </div>
            <div class="d-flex flex-wrap">
              <div class="w-50">
                <div class="d-flex" style="align-items: center">
                  <img src="../assets/icon_hours.png" alt="" />
                  <div class="final-text ml-20">Hours Of Operation</div>
                </div>
                <div class="hours-text mt-8">Monday to Sunday 11am – 10pm</div>
                <div class="hours-text mt-8">Last order 9:30pm</div>
              </div>
              <div class="w-50">
                <div class="d-flex" style="align-items: center">
                  <img src="../assets/icon_dining.png" alt="" />
                  <div class="final-text ml-20">Dining Style</div>
                </div>
                <div class="hours-text mt-8">Casual Dining</div>
              </div>
              <div class="w-50 mt-30">
                <div class="d-flex" style="align-items: center">
                  <img src="../assets/icon_cuisines.png" alt="" />
                  <div class="final-text ml-20">Cuisines</div>
                </div>
                <div class="hours-text mt-8">Sichuan, Spicy Chinese food</div>
              </div>
              <div class="w-50 mt-30">
                <div class="d-flex" style="align-items: center">
                  <img src="../assets/icon_dress.png" alt="" />
                  <div class="final-text ml-20">Dress code</div>
                </div>
                <div class="hours-text mt-8">Casual Dress</div>
              </div>
              <div class="w-50 mt-30">
                <div class="d-flex" style="align-items: center">
                  <img src="../assets/icon_bus.png" alt="" />
                  <div class="final-text ml-20">Public transit</div>
                </div>
                <div class="hours-text mt-8">34th st Herald Square Station DFNQMR ( 3 min walking)
Or Penn Station 123F (6 min walking)
</div>
              </div>
              <div class="w-50 mt-30">
                <div class="d-flex" style="align-items: center">
                  <img src="../assets/icon_park.png" alt="" />
                  <div class="final-text ml-20">Parking details</div>
                </div>
                <div class="hours-text mt-8">Street parking after 7pm</div>
              </div>
              <div class="w-50 mt-30">
                <div class="d-flex" style="align-items: center">
                  <img src="../assets/icon_payment.png" alt="" />
                  <div class="final-text ml-20">Payment options</div>
                </div>
                <div class="hours-text mt-8">AMEX, JCB, MasterCard, Visa</div>
              </div>
              <div class="w-50 mt-30">
                <div class="d-flex" style="align-items: center">
                  <img src="../assets/icon_chef.png" alt="" />
                  <div class="final-text ml-20">Executive chef</div>
                </div>
                <div class="hours-text mt-8">Chef Yu</div>
              </div>
              <div class="w-50 mt-30">
                <div class="d-flex" style="align-items: center">
                  <img src="../assets/icon_additional.png" alt="" />
                  <div class="final-text ml-20">Additional</div>
                </div>
                <div class="hours-text mt-8">
                  Banquet, Bar/Lounge, Counter Seating, Non-Smoking, Outdoor
                  dining, Patio/Outdoor Dining, View
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="Photos" name="second"></el-tab-pane>
          <el-tab-pane label="Menu" name="third"></el-tab-pane>
          <el-tab-pane label="Other" name="fourth"></el-tab-pane> -->
        </el-tabs>
      </div>
      <div class="reserve-right">
        <div class="reserve-right-title mt-20">Make A Reservation</div>
        <div class="reserve-title mt-30">Party size</div>
        <div class="tran-input">
          <el-select
            class="mt-8 mt-20"
            v-model="personValue"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in personArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="d-flex tran-input" style="justify-content: space-between">
          <div style="width: 49%">
            <div class="reserve-title mt-30">Date</div>
            <el-date-picker
              class="mt-8"
              v-model="value1"
              type="date"
              @change="dateChangeHandler"
              :placeholder="$t('message.date')"
            >
            </el-date-picker>
          </div>
          <div style="width: 49%">
            <div class="reserve-title mt-30">Time</div>
            <el-select
              class="mt-8"
              @change="timeChange"
              v-model="timeValue"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in timesArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="reserve-title mt-30">Seat selected</div>
        <div class="d-flex mt-20" style="align-items: center">
          <div class="choice" @click="choiceHandler">CHOICE</div>
          <div class="choice-text ml-20">{{ selfSelectArr.join(",") }}</div>
        </div>
        <div class="dashbord mt-20 mb-20"></div>
        <el-input
          class="mb-20 mt-30"
          v-model="name"
          placeholder="Name"
        ></el-input>
        <el-input class="mb-20" v-model="email" placeholder="Email"></el-input>
        <el-input
          class="mb-20"
          v-model="phone"
          placeholder="Phone Number"
        ></el-input>
        <div
          class="confirm-app mb-30"
          @click="confirmHandler"
          :class="{
            confirmA: selfSelectArr.length > 0 && name && email && phone,
          }"
        >
          Confirm appointment
        </div>
      </div>
    </div>
    <el-dialog
      title="Choose a place to eat"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
      <div class="demo-home mb-60">
        <div class="first">
          <div class="s-home">
            <div class="first-s option-seat"></div>
            <div class="second-s option-seat"></div>
          </div>
          <div class="ml-20">Optional seats</div>
        </div>
        <div class="first">
          <div class="s-home">
            <div class="first-s seat-selected"></div>
            <div class="second-s seat-selected"></div>
          </div>
          <div class="ml-20">Seat selected</div>
        </div>
        <div class="first">
          <div class="s-home">
            <div class="first-s seat-not"></div>
            <div class="second-s seat-not"></div>
          </div>
          <div class="ml-20">Seats not available</div>
        </div>
      </div>
      <div class="kuan">
        <div
          v-for="(item, index) in numArr"
          :key="index"
          class="s-home"
          :class="item"
          @click="getNum(item)"
        >
          <div
            :class="{ 'seat-not': selectedNumArr.includes(item) }"
            class="first-s option-seat"
          ></div>
          <div
            :class="{ 'seat-not': selectedNumArr.includes(item) }"
            class="second-s option-seat"
          >
            {{ item }}
          </div>
        </div>
        <div class="floor2-home">
          <div class="s-home floor2">
            <div class="first-s option-seat"></div>
            <div class="second-s option-seat">2nd</div>
          </div>
          <div class="floor2-text">Second Floor</div>
        </div>
        <div class="patio-home">
          <div class="s-home floor2">
            <div class="first-s option-seat"></div>
            <div class="second-s option-seat">pa</div>
          </div>
          <div class="floor2-text">Patio</div>
        </div>
        <div class="ground-home">
          <div class="s-home floor2">
            <div class="first-s option-seat"></div>
            <div class="second-s option-seat">gf</div>
          </div>
          <div class="floor2-text">ground floor</div>
        </div>

        <!-- <div class="s-home R6" @click="getNum('R6')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">R6</div>
                </div>
                <div class="s-home R4" @click="getNum('R4')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">R4</div>
                </div>
                <div class="s-home T1" @click="getNum('T1')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">T1</div>
                </div>
                <div class="s-home T2" @click="getNum('T2')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">T2</div>
                </div>
                <div class="s-home T3" @click="getNum('T3')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">T3</div>
                </div>
                <div class="s-home R5" @click="getNum('R5')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">R5</div>
                </div>
                <div class="s-home R7" @click="getNum('R7')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">R7</div>
                </div>
                <div class="s-home D16" @click="getNum('D16')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D16</div>
                </div>
                <div class="s-home D17" @click="getNum('D17')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D17</div>
                </div>
                <div class="s-home D18" @click="getNum('D18')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D18</div>
                </div>
                <div class="s-home D15" @click="getNum('D15')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D15</div>
                </div>
                <div class="s-home D14" @click="getNum('D14')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D14</div>
                </div>
                <div class="s-home D4" @click="getNum('D4')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D4</div>
                </div>
                <div class="s-home D3" @click="getNum('D3')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D3</div>
                </div>
                <div class="s-home D13" @click="getNum('D13')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D13</div>
                </div>
                <div class="s-home D12" @click="getNum('D12')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D12</div>
                </div>
                <div class="s-home D9" @click="getNum('D9')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D9</div>
                </div>
                <div class="s-home D8" @click="getNum('D8')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D8</div>
                </div>
                <div class="s-home D19" @click="getNum('D19')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D19</div>
                </div>
                <div class="s-home D20" @click="getNum('D20')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D20</div>
                </div>
                <div class="s-home D10" @click="getNum('D10')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D10</div>
                </div>
                <div class="s-home D11" @click="getNum('D11')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D11</div>
                </div>
                <div class="s-home D7" @click="getNum('D7')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D7</div>
                </div>
                <div class="s-home D6" @click="getNum('D6')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D6</div>
                </div>
                <div class="s-home D5" @click="getNum('D5')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D5</div>
                </div>
                <div class="s-home D2" @click="getNum('D2')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D2</div>
                </div>
                <div class="s-home D3" @click="getNum('D3')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">D3</div>
                </div>
                <div class="s-home S1" @click="getNum('S1')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">S1</div>
                </div>
                <div class="s-home S2" @click="getNum('S2')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">S2</div>
                </div>
                <div class="s-home S3" @click="getNum('S3')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">S3</div>
                </div>
                <div class="s-home S4" @click="getNum('S4')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">S4</div>
                </div>
                <div class="s-home S5" @click="getNum('S5')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">S5</div>
                </div>
                <div class="s-home W4" @click="getNum('W4')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">W4</div>
                </div>
                <div class="s-home W3" @click="getNum('W3')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">W3</div>
                </div>
                <div class="s-home W2" @click="getNum('W2')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">W2</div>
                </div>
                <div class="s-home W1" @click="getNum('W1')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">W1</div>
                </div>
                <div class="s-home C4" @click="getNum('C4')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">C4</div>
                </div>
                <div class="s-home C3" @click="getNum('C3')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">C3</div>
                </div>
                <div class="s-home C2" @click="getNum('C2')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">C2</div>
                </div>
                <div class="s-home C1" @click="getNum('C1')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">C1</div>
                </div>
                <div class="s-home VIP" @click="getNum('VIP')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">VIP</div>
                </div>
                <div class="s-home VIP0" @click="getNum('0VIP')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">0VIP</div>
                </div>
                <div class="s-home B8" @click="getNum('B8')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">B8</div>
                </div>
                <div class="s-home B7" @click="getNum('B7')">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">B7</div>
                </div>
                <div class="s-home B6" >
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">B6</div>
                </div>
                <div class="s-home B5">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">B5</div>
                </div>
                <div class="s-home B4">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">B4</div>
                </div>
                <div class="s-home B3">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">B3</div>
                </div>
                <div class="s-home B2">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">B2</div>
                </div>
                <div class="s-home B1">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">B1</div>
                </div>
                <div class="s-home L8">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">L8</div>
                </div>
                <div class="s-home L7">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">L7</div>
                </div>
                <div class="s-home L6">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">L6</div>
                </div>
                <div class="s-home L5">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">L5</div>
                </div>
                <div class="s-home L4">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">L4</div>
                </div>
                <div class="s-home L3">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">L3</div>
                </div>
                <div class="s-home L2">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">L2</div>
                </div>
                <div class="s-home L1">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">L1</div>
                </div>
                <div class="s-home o5">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">05</div>
                </div>
                <div class="s-home o4">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">04</div>
                </div>
                <div class="s-home o3">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">03</div>
                </div>
                <div class="s-home o2">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">02</div>
                </div>
                <div class="s-home o1">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">01</div>
                </div>
                <div class="s-home o6">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">06</div>
                </div>
                <div class="s-home o7">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">07</div>
                </div>
                <div class="s-home o8">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">08</div>
                </div>
                <div class="s-home o8">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">08</div>
                </div>
                <div class="s-home o9">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">09</div>
                </div>
                <div class="s-home o10">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">010</div>
                </div>
                <div class="s-home R2">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">R2</div>
                </div>
                <div class="s-home R1">
                    <div class="first-s option-seat"></div>
                    <div class="second-s option-seat">R1</div>
                </div> -->
        <img class="" src="../assets/kuan.png" alt="" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >confirm</el-button
        >
      </span>
    </el-dialog>
    <CtFooter></CtFooter>
  </div>
</template>

<script>
import CtFooter from "../components/Footer";
import { MessageBox, Loading } from "element-ui";
// import { Loading } from 'element-ui';
export default {
  components: {
    CtFooter,
  },
  data() {
    return {
      selfSelectArr: [],
      selectArr: [],
      selectedNumArr: [],
      dialogVisible: false,
      numArr: [
        "R6",
        "R4",
        "T1",
        "T2",
        "T3",
        "R5",
        "R7",
        "D16",
        "D17",
        "D18",
        "D15",
        "D14",
        "D4",
        "D3",
        "D13",
        "D12",
        "D9",
        "D8",
        "D19",
        "D20",
        "D10",
        "D11",
        "D7",
        "D6",
        "D5",
        "D2",
        "S1",
        "S2",
        "S3",
        "S4",
        "S5",
        "W4",
        "W3",
        "W2",
        "W1",
        "C4",
        "C3",
        "C2",
        "C1",
        "VIP",
        "OVIP",
        "B8",
        "B7",
        "B6",
        "B5",
        "B4",
        "B3",
        "B2",
        "B1",
        "L8",
        "L7",
        "L6",
        "L5",
        "L4",
        "L3",
        "L2",
        "L1",
        "O5",
        "O4",
        "O3",
        "O2",
        "O1",
        "O6",
        "O7",
        "O8",
        "O9",
        "O10",
        "R2",
        "R1",
      ],
      name: "",
      email: "",
      phone: "",
      value1: new Date(),
      activeName: "first",
      personArr: [
        { value: "1", label: "1 person" },
        { value: "2", label: "2 people" },
        { value: "3", label: "3 people" },
        { value: "4", label: "4 people" },
        { value: "5", label: "5 people" },
        { value: "6", label: "6 people" },
        { value: "7", label: "7 people" },
        { value: "8", label: "8 people" },
        { value: "9", label: "9 people" },
        { value: "10", label: "10 people" },
        { value: "11", label: "11 people" },
        { value: "12", label: "12 people" },
        { value: "13", label: "13 people" },
        { value: "14", label: "14 people" },
        { value: "15", label: "15 people" },
        { value: "16", label: "16 people" },
        { value: "17", label: "17 people" },
        { value: "18", label: "18 people" },
        { value: "19", label: "19 people" },
        { value: "20", label: "20 people" },
        { value: "21", label: "Larger party" },
      ],
      personValue: "2",
      timeValue: "11:00 AM",
      timesArr: [
        { value: "11:00 AM", label: "11:00 AM" },
        { value: "11:30 AM", label: "11:30 AM" },
        { value: "12:00 AM", label: "12:00 AM" },
        { value: "12:30 AM", label: "12:30 AM" },
        { value: "1:00 PM", label: "1:00 PM" },
        { value: "1:30 PM", label: "1:30 PM" },
        { value: "2:00 PM", label: "2:00 PM" },
        { value: "2:30 PM", label: "2:30 PM" },
        { value: "3:00 PM", label: "3:00 PM" },
        { value: "3:30 PM", label: "3:30 PM" },
        { value: "4:00 PM", label: "4:00 PM" },
        { value: "4:30 PM", label: "4:30 PM" },
        { value: "5:00 PM", label: "5:00 PM" },
        { value: "5:30 PM", label: "5:30 PM" },
        { value: "6:00 PM", label: "6:00 PM" },
        { value: "6:30 PM", label: "6:30 PM" },
        { value: "7:00 PM", label: "7:00 PM" },
        { value: "7:30 PM", label: "7:30 PM" },
        { value: "8:00 PM", label: "8:00 PM" },
        { value: "8:30 PM", label: "8:30 PM" },
        { value: "9:00 PM", label: "9:00 PM" },
        { value: "9:30 PM", label: "9:30 PM" },
      ],
    };
  },
  methods: {
    dateChangeHandler() {
      //   console.log(this.dateFormat(date))
      this.getReserveData();
      this.resetForm();
      this.resetSelectSeat();
    },
    timeChange() {
      this.getReserveData();
      this.resetForm();
      this.resetSelectSeat();
    },
    resetSelectSeat() {
      let getDom = document
        .getElementsByClassName("kuan")[0]
        .getElementsByClassName("option-seat");
      //   console.log(getDom)
      //   let newArr = [];
      //     for(let i=0; i<getDom.length; i++){
      //         newArr.push(getDom[i]);
      //     }
      //     for(let i=0; i<newArr.length; i++){
      //         getDom[i].classList.remove('seat-selected')
      //     }
      for (let i = 0; i < getDom.length; i++) {
        getDom[i].classList.remove("seat-selected");
        //   console.log(getDom)
      }
    },
    getNum(num) {
      //   console.log(num)
      // let newArr = []
      let getDom = document.getElementsByClassName(num)[0];
      if (getDom.childNodes[0].classList.contains("seat-not") == true) {
        this.$message.error("This seat has been reserved!");
        return;
      }

      if (!this.selectArr.includes(num)) {
        this.selectArr.push(num);
        this.selfSelectArr.push(num);
      } else {
        this.selectArr.splice(
          this.selectArr.findIndex((item) => item === num),
          1
        );
        this.selfSelectArr.splice(
          this.selectArr.findIndex((item) => item === num),
          1
        );
      }
      //   this.selfSelectArr = newArr.push(num)
      //   console.log(this.selfSelectArr)

      if (getDom.childNodes[0].classList.contains("seat-selected") == true) {
        getDom.childNodes[0].classList.remove("seat-selected");
        getDom.childNodes[1].classList.remove("seat-selected");
      } else {
        getDom.childNodes[0].classList.add("seat-selected");
        getDom.childNodes[1].classList.add("seat-selected");
      }

      //   console.log(getDom.childNodes)
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleClose(done) {
      MessageBox.confirm("Confirm？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    choiceHandler() {
      this.dialogVisible = true;
    },
    agentHandler() {
      console.log(888);
    },
    dateFormat(dateData) {
      var date = new Date(dateData);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
    confirmHandler() {
      //   const loading = this.$loading
      //     console.log(loading)

      let obj = {};
      obj.email = this.email;
      obj.num = JSON.stringify(this.selectArr);
      obj.peopleNum = this.personValue;
      obj.phone = this.phone;
      obj.name = this.name;
      obj.bookingDate = this.dateFormat(this.value1);
      obj.bookingTime = this.timeValue;
      //   obj.
      //   console.log(obj)
      if (this.selfSelectArr.length == "0") {
        this.$message.error("Please select seat！");
        return;
      }
      if (obj.email == "" || obj.phone == "" || obj.name == "") {
        this.$message.error("Please make sure that every item is filled in！");
      } else {
        const loading = Loading.service({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.$axios.post("api/seat/add", obj).then((res) => {
          console.log(res)
          loading.close();
          if (res.data.code == 200) {
            // this.$message.success(
            //   {
            //     duration: 0,
            //     showClose: true,
            //     message: 'Due to the COVID-19, CDC mandatory 6 feet social distances, Your table will be arranged by the manager.',
            //     type: 'success'
            //   }
            // );
            MessageBox.alert('Due to the COVID-19, CDC mandatory 6 feet social distances, Your table will be arranged by the manager', 'Attention', {
          confirmButtonText: 'Confirm',
          type: 'warning'
        });
          }
          else if (res.data.code == 500) {
              MessageBox.alert(res.data.message, 'Attention', {
          confirmButtonText: 'Confirm',
          type: 'error'
        });
          }
          this.getReserveData();
          this.resetForm();
        });
      }
    },
    resetForm() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.selfSelectArr = [];
    },
    deleteNowClass() {
      let chArr = document.body.getElementsByClassName("seat-selected");
      for (let i = 0; i < chArr.length; i++) {
        //删除元素 元素.parentNode.removeChild(元素);
        if (chArr[i] != null) chArr[i].removeChild(chArr[i]);
      }
    },
    getReserveData() {
      let obj = {};
      obj.bookingDate = this.dateFormat(this.value1);
      obj.bookingTime = this.timeValue;
      this.$axios.post("api/seat/list", obj).then((res) => {
        if (res.data.data.length != "0") {
          let arr = [];
          res.data.data.forEach((item) => {
            arr = arr.concat(JSON.parse(item.num));
          });

          this.selectedNumArr = arr;
        } else {
          this.selectedNumArr = [];
        }
        // console.log(this.selectedNumArr)
      });
    },
  },
  mounted() {
    this.getReserveData();
  },
};
</script>

<style lang="scss">
.reserve {
  .el-select-dropdown__item.selected {
    color: #3f231b !important;
  }
  input::-webkit-input-placeholder {
    font-family: Barlow;
  }
  .reserve-bg {
    height: 300px;
    width: 100%;
    background-image: url("../assets/banner2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .reserve-home {
    display: flex;
    width: 90%;
    margin: 0 auto;
    // height: 1000px;
    justify-content: space-between;
    margin-top: -60px;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  .reserve-left {
    width: 70%;
    // height: 500px;
    // border: 1px solid #000;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    //         box-shadow: 0px 0px 6px 0px
    // 	rgba(0, 0, 0, 0.16);
    // border-radius: 4px;
  }
  .reserve-right {
    width: 27%;
    // height: 500px;
    // border: 1px solid #000;
    background-color: #fff;
    padding: 0 40px;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    .el-input__inner {
      font-size: 16px;
    }
  }
  .el-tabs__item.is-active {
    color: #3f231b;
  }
  .el-tabs__active-bar {
    background-color: #3f231b;
  }
  .tip-cards {
    width: 5px;
    height: 20px;
    background-color: #3f231b;
  }
  .tip-text {
    font-family: Barlow-bold;
    font-size: 24px;
    color: #3f231b;
  }
  .tip-desc {
    font-family: Barlow;
    font-size: 16px;
    color: #333333;
    line-height: 26px;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .cleaning-sanitizing {
    font-family: Barlow-bold;
    font-size: 17px;
    color: #3f231b;
  }
  .more-btn {
    font-size: 18px;
    color: #333333;
    cursor: pointer;
  }
  .final-text {
    font-size: 18px;
    color: #333333;
    font-family: Barlow-bold;
  }
  .hours-text {
    font-family: Barlow;
    font-size: 18px;
    color: #333333;
    margin-left: 50px;
  }
  .reserve-right-title {
    font-family: Barlow-bold;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }
  .reserve-title {
    font-family: Barlow-bold;
    font-size: 18px;
    color: #333333;
  }
  .tran-input .el-input__inner {
    border: none;
    width: 100%;
    border-bottom: solid 1px #d6d6d6;
  }
  .el-select {
    width: 100%;
  }
  .el-date-editor.el-input {
    width: unset;
  }
  .choice {
    width: 107px;
    height: 35px;
    line-height: 35px;
    background-color: #3f231b;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    cursor: pointer;
  }
  .dashbord {
    border: 1px dashed #eee;
  }
  .confirm-app {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #dedede;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: all 0.5s;
  }
  .kuan {
    position: relative;
    width: 900px;
    height: 900px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .demo-home {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .s-home {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 45px;
    cursor: pointer;
  }
  .first-s {
    width: 25px;
    height: 8px;
    border-radius: 4px;
    // background-color: yellow;
    box-sizing: border-box;
  }
  .second-s {
    width: 38px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
    margin-top: 5px;
    box-sizing: border-box;
    // background-color: yellow;
  }
  .first {
    display: flex;
    align-items: center;
  }
  .option-seat {
    border: solid 1px #3f231b;
  }
  .seat-selected {
    background-color: #60b76b;
    color: #fff !important;
  }
  .seat-not {
    background-color: #e0e0e0;
    color: #fff;
  }
  .el-input__inner:focus {
    border-color: #3f231b;
  }

  .R6 {
    position: absolute;
    top: 72px;
    left: 34px;
    .option-seat {
      border: solid 1px #f7724c;
      color: #f7724c;
    }
  }
  .R4 {
    position: absolute;
    top: 72px;
    left: 85px;
  }
  .T1 {
    position: absolute;
    top: 72px;
    left: 136px;
    .option-seat {
      border: solid 1px #f7724c;
      color: #f7724c;
    }
  }
  .T2 {
    position: absolute;
    top: 156px;
    left: 136px;
    .option-seat {
      border: solid 1px #f7724c;
      color: #f7724c;
    }
  }
  .T3 {
    position: absolute;
    top: 240px;
    left: 136px;
    .option-seat {
      border: solid 1px #f7724c;
      color: #f7724c;
    }
  }
  .R5 {
    position: absolute;
    top: 240px;
    left: 85px;
  }
  .R7 {
    position: absolute;
    top: 240px;
    left: 34px;
    .option-seat {
      border: solid 1px #f7724c;
      color: #f7724c;
    }
  }
  .D16 {
    position: absolute;
    top: 40px;
    left: 213px;
  }
  .D17 {
    position: absolute;
    top: 40px;
    left: 258px;
  }
  .D18 {
    position: absolute;
    top: 40px;
    left: 303px;
  }
  .D15 {
    position: absolute;
    top: 119px;
    left: 213px;
  }
  .D14 {
    position: absolute;
    top: 198px;
    left: 213px;
  }
  .D4 {
    position: absolute;
    top: 277px;
    left: 223px;
  }
  .D3 {
    position: absolute;
    top: 277px;
    left: 265px;
  }
  .D13 {
    position: absolute;
    top: 119px;
    left: 258px;
  }
  .D12 {
    position: absolute;
    top: 198px;
    left: 258px;
  }
  .D9 {
    position: absolute;
    top: 111px;
    left: 330px;
  }
  .D8 {
    position: absolute;
    top: 191px;
    left: 330px;
  }
  .D19 {
    position: absolute;
    top: 10px;
    left: 393px;
  }
  .D20 {
    position: absolute;
    top: 10px;
    left: 437px;
  }
  .D10 {
    position: absolute;
    top: 111px;
    left: 380px;
  }
  .D11 {
    position: absolute;
    top: 111px;
    left: 430px;
  }
  .D7 {
    position: absolute;
    top: 191px;
    left: 375px;
  }
  .D6 {
    position: absolute;
    top: 191px;
    left: 420px;
  }
  .D5 {
    position: absolute;
    top: 191px;
    left: 465px;
  }
  .D2 {
    position: absolute;
    top: 277px;
    left: 338px;
  }
  .D3 {
    position: absolute;
    top: 277px;
    left: 386px;
  }
  .S1 {
    position: absolute;
    top: 53px;
    left: 496px;
  }
  .S2 {
    position: absolute;
    top: 107px;
    left: 548px;
  }
  .S3 {
    position: absolute;
    top: 161px;
    left: 603px;
  }
  .S4 {
    position: absolute;
    top: 161px;
    left: 519px;
  }
  .S5 {
    position: absolute;
    top: 198px;
    left: 565px;
  }
  .W4 {
    position: absolute;
    top: 231px;
    left: 656px;
  }
  .W3 {
    position: absolute;
    top: 286px;
    left: 689px;
  }
  .W2 {
    position: absolute;
    top: 342px;
    left: 717px;
  }
  .W1 {
    position: absolute;
    top: 398px;
    left: 743px;
  }
  .C4 {
    position: absolute;
    top: 344px;
    left: 442px;
  }
  .C3 {
    position: absolute;
    top: 344px;
    left: 492px;
  }
  .C2 {
    position: absolute;
    top: 405px;
    left: 540px;
  }
  .C1 {
    position: absolute;
    top: 474px;
    left: 512px;
  }
  .VIP {
    position: absolute;
    top: 425px;
    left: 694px;
  }
  .OVIP {
    position: absolute;
    top: 463px;
    left: 641px;
    .option-seat {
      border: solid 1px #f7724c;
      color: #f7724c;
    }
  }
  .B8 {
    position: absolute;
    top: 495px;
    left: 694px;
  }
  .B7 {
    position: absolute;
    top: 542px;
    left: 694px;
  }
  .B6 {
    position: absolute;
    top: 588px;
    left: 694px;
  }
  .B5 {
    position: absolute;
    top: 634px;
    left: 694px;
  }
  .B4 {
    position: absolute;
    top: 679px;
    left: 694px;
  }
  .B3 {
    position: absolute;
    top: 725px;
    left: 694px;
  }
  .B2 {
    position: absolute;
    top: 771px;
    left: 694px;
  }
  .B1 {
    position: absolute;
    top: 771px;
    left: 742px;
  }
  .L8 {
    position: absolute;
    top: 538px;
    left: 560px;
  }
  .L7 {
    position: absolute;
    top: 538px;
    left: 512px;
  }
  .L6 {
    position: absolute;
    top: 538px;
    left: 464px;
  }
  .L5 {
    position: absolute;
    top: 592px;
    left: 464px;
  }
  .L4 {
    position: absolute;
    top: 646px;
    left: 464px;
  }
  .L3 {
    position: absolute;
    top: 698px;
    left: 464px;
  }
  .L2 {
    position: absolute;
    top: 698px;
    left: 512px;
  }
  .L1 {
    position: absolute;
    top: 698px;
    left: 560px;
  }
  .O5 {
    position: absolute;
    top: 28px;
    left: 657px;
    .option-seat {
      border: solid 1px #60b76b;
      color: #60b76b;
    }
  }
  .O4 {
    position: absolute;
    top: 93px;
    left: 675px;
    .option-seat {
      border: solid 1px #60b76b;
      color: #60b76b;
    }
  }
  .O3 {
    position: absolute;
    top: 151px;
    left: 705px;
    .option-seat {
      border: solid 1px #60b76b;
      color: #60b76b;
    }
  }
  .O2 {
    position: absolute;
    top: 210px;
    left: 734px;
    .option-seat {
      border: solid 1px #60b76b;
      color: #60b76b;
    }
  }
  .O1 {
    position: absolute;
    top: 265px;
    left: 758px;
    .option-seat {
      border: solid 1px #60b76b;
      color: #60b76b;
    }
  }
  .O6 {
    position: absolute;
    top: 13px;
    left: 758px;
    .option-seat {
      border: solid 1px #60b76b;
      color: #60b76b;
    }
  }
  .O7 {
    position: absolute;
    top: 67px;
    left: 815px;
    .option-seat {
      border: solid 1px #60b76b;
      color: #60b76b;
    }
  }
  .O8 {
    position: absolute;
    top: 126px;
    left: 815px;
    .option-seat {
      border: solid 1px #60b76b;
      color: #60b76b;
    }
  }
  .O9 {
    position: absolute;
    top: 182px;
    left: 815px;
    .option-seat {
      border: solid 1px #60b76b;
      color: #60b76b;
    }
  }
  .O10 {
    position: absolute;
    top: 237px;
    left: 815px;
    .option-seat {
      border: solid 1px #60b76b;
      color: #60b76b;
    }
  }
  .R2 {
    position: absolute;
    bottom: 176px;
    left: 839px;
  }
  .R1 {
    position: absolute;
    bottom: 20px;
    left: 809px;
  }
  .floor2-home {
    display: flex;
    position: absolute;
    top: 463px;
    align-items: center;
    left: 60px;
    .option-seat {
      border: solid 1px #f7724c;
    }
    color: #f7724c;
    .floor2-text {
      margin-left: 20px;
    }
  }
  .patio-home {
    display: flex;
    position: absolute;
    top: 536px;
    align-items: center;
    left: 60px;
    .option-seat {
      border: solid 1px #60b76b;
      color: #60b76b;
    }
    color: #60b76b;
    .floor2-text {
      margin-left: 20px;
    }
  }
  .ground-home {
    display: flex;
    position: absolute;
    top: 609px;
    align-items: center;
    left: 60px;
    .option-seat {
      border: solid 1px #3f231b;
      color: #3f231b;
    }
    color: #3f231b;
    .floor2-text {
      margin-left: 20px;
    }
  }
  .el-dialog {
    min-width: 1152px;
  }
  .confirmA {
    background-color: #3f231b;
  }
  // .el-message-box {
  //   .el-button--primary {
  //     background-color: 
  //   }
  // }
}
</style>